exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-aviso-legal-index-jsx": () => import("./../../../src/data/pages/aviso-legal/index.jsx" /* webpackChunkName: "component---src-data-pages-aviso-legal-index-jsx" */),
  "component---src-data-pages-contacto-index-jsx": () => import("./../../../src/data/pages/contacto/index.jsx" /* webpackChunkName: "component---src-data-pages-contacto-index-jsx" */),
  "component---src-data-pages-estrenimiento-causas-index-jsx": () => import("./../../../src/data/pages/estrenimiento/causas/index.jsx" /* webpackChunkName: "component---src-data-pages-estrenimiento-causas-index-jsx" */),
  "component---src-data-pages-estrenimiento-index-jsx": () => import("./../../../src/data/pages/estrenimiento/index.jsx" /* webpackChunkName: "component---src-data-pages-estrenimiento-index-jsx" */),
  "component---src-data-pages-estrenimiento-mitos-index-jsx": () => import("./../../../src/data/pages/estrenimiento/mitos/index.jsx" /* webpackChunkName: "component---src-data-pages-estrenimiento-mitos-index-jsx" */),
  "component---src-data-pages-estrenimiento-que-es-index-jsx": () => import("./../../../src/data/pages/estrenimiento/que-es/index.jsx" /* webpackChunkName: "component---src-data-pages-estrenimiento-que-es-index-jsx" */),
  "component---src-data-pages-estrenimiento-tipos-index-jsx": () => import("./../../../src/data/pages/estrenimiento/tipos/index.jsx" /* webpackChunkName: "component---src-data-pages-estrenimiento-tipos-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-mapa-del-sitio-index-jsx": () => import("./../../../src/data/pages/mapa-del-sitio/index.jsx" /* webpackChunkName: "component---src-data-pages-mapa-del-sitio-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-infantil-alimentacion-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-infantil/alimentacion/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-infantil-alimentacion-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-infantil-causas-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-infantil/causas/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-infantil-causas-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-infantil-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-infantil/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-infantil-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-infantil-recomendaciones-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-infantil/recomendaciones/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-infantil-recomendaciones-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-infantil-test-escala-bristol-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-infantil/test-escala-bristol/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-infantil-test-escala-bristol-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-lactantes-alimentacion-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-lactantes/alimentacion/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-lactantes-alimentacion-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-lactantes-causas-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-lactantes/causas/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-lactantes-causas-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-lactantes-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-lactantes/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-lactantes-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-lactantes-recomendaciones-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-lactantes/recomendaciones/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-lactantes-recomendaciones-index-jsx" */),
  "component---src-data-pages-ninos-estrenimiento-lactantes-test-escala-bristol-index-jsx": () => import("./../../../src/data/pages/ninos/estrenimiento-lactantes/test-escala-bristol/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-estrenimiento-lactantes-test-escala-bristol-index-jsx" */),
  "component---src-data-pages-ninos-index-jsx": () => import("./../../../src/data/pages/ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-ninos-index-jsx" */),
  "component---src-data-pages-politica-cookies-index-jsx": () => import("./../../../src/data/pages/politica-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-politica-cookies-index-jsx" */),
  "component---src-data-pages-politica-de-privacidad-index-jsx": () => import("./../../../src/data/pages/politica-de-privacidad/index.jsx" /* webpackChunkName: "component---src-data-pages-politica-de-privacidad-index-jsx" */),
  "component---src-data-pages-politica-privacidad-index-jsx": () => import("./../../../src/data/pages/politica-privacidad/index.jsx" /* webpackChunkName: "component---src-data-pages-politica-privacidad-index-jsx" */),
  "component---src-data-pages-por-que-rovi-index-jsx": () => import("./../../../src/data/pages/por-que-rovi/index.jsx" /* webpackChunkName: "component---src-data-pages-por-que-rovi-index-jsx" */),
  "component---src-data-pages-preguntas-frecuentes-estrenimiento-index-jsx": () => import("./../../../src/data/pages/preguntas-frecuentes-estrenimiento/index.jsx" /* webpackChunkName: "component---src-data-pages-preguntas-frecuentes-estrenimiento-index-jsx" */),
  "component---src-data-pages-productos-index-jsx": () => import("./../../../src/data/pages/productos/index.jsx" /* webpackChunkName: "component---src-data-pages-productos-index-jsx" */),
  "component---src-data-pages-productos-rovi-adultos-index-jsx": () => import("./../../../src/data/pages/productos/rovi-adultos/index.jsx" /* webpackChunkName: "component---src-data-pages-productos-rovi-adultos-index-jsx" */),
  "component---src-data-pages-productos-rovi-ninos-index-jsx": () => import("./../../../src/data/pages/productos/rovi-ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-productos-rovi-ninos-index-jsx" */),
  "component---src-data-pages-supositorios-de-glicerina-index-jsx": () => import("./../../../src/data/pages/supositorios-de-glicerina/index.jsx" /* webpackChunkName: "component---src-data-pages-supositorios-de-glicerina-index-jsx" */),
  "component---src-data-pages-supositorios-de-glicerina-origen-vegetal-index-jsx": () => import("./../../../src/data/pages/supositorios-de-glicerina/origen-vegetal/index.jsx" /* webpackChunkName: "component---src-data-pages-supositorios-de-glicerina-origen-vegetal-index-jsx" */),
  "component---src-data-pages-supositorios-de-glicerina-que-son-index-jsx": () => import("./../../../src/data/pages/supositorios-de-glicerina/que-son/index.jsx" /* webpackChunkName: "component---src-data-pages-supositorios-de-glicerina-que-son-index-jsx" */),
  "component---src-data-pages-supositorios-de-glicerina-ventajas-index-jsx": () => import("./../../../src/data/pages/supositorios-de-glicerina/ventajas/index.jsx" /* webpackChunkName: "component---src-data-pages-supositorios-de-glicerina-ventajas-index-jsx" */)
}

